import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CircleType from 'circletype';
import Service from './blocks/Service'
import Navigation from './navigation/Navigation';
import Contact from './buttons/Contact';


const Container = styled.div`
    background: var(--tan);
    height: auto;
    padding-bottom: 5rem;

    .title{
        padding-top: 10rem;
    }

    .rivalSchool{
        float: right;
        letter-spacing: var(--letter-spacing-button);
    }

    button{
        margin-top: 10rem;
    }
`

const Services = () => {
    const services = useRef(null);

    const allServices = [
        {
            title: 'APP & WEB DEVELOPMENT',
            details: 'Custom development from web apps, native mobile apps, software and websites. We go from write frames to production deployment. We offer a range of pricing, including partnering equity options.'
        },
        {
            title: 'BRANDING & DESIGN',
            details: 'Brand Identity is so pertinent to establishing both confidence in your product and customer demographic. This directly affects marketing and ways your product is seen. We offer solutions from logo design, branding guides to even physical branding materials.'
        },
        {
            title: 'TRAINING',
            details: "We offer training, based off years of experience and education, on how to run software as a service, tech start-ups, how to lead teams, how to deliver feedback. We also train on ways to build and establish strong social media content, how to code your next app and more. Message us for more details."
        },

    ]

    useEffect(() => {
        new CircleType(services.current).radius(800);
    },[])

    return(
        <Container>
            <Navigation position="left"/>
            <h5 className="rivalSchool" id="services">RIVAL SCHOOL</h5>
            <h1 className="title" ref={services}>SERVICES</h1>
                {
                allServices.map((service) => {
                    return(<Service key={service.title} title={ service.title } details={ service.details }/>)
                })
            }
            <a href="#contact"><Contact title="CONTACT" color="black"/></a>
        </Container>
    )
}
export default Services