import React, { useEffect } from 'react'
import styled from 'styled-components'
import ContactButton from './buttons/Contact'
import Navigation from './navigation/Navigation'
import Colors from './buttons/Colors'
import { useStaticQuery, graphql } from "gatsby"
import netlifyIdentity from "netlify-identity-widget";
import Img from "gatsby-image"

const Container = styled.div`
    height: 100vh;

    .header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;

        .rivalSchool{
            text-align: right;
            letter-spacing: var(--letter-spacing-button);
        }
    }

    .form-container{
        .contact-header{
            display: block;
            width: 60%;
            margin: 0 auto;
            margin-top: 5rem;
            border: 20px solid var(--black);
            padding: 5rem;
        }

        .title-container{
            border: 2rem solid var(--black);
            padding-top: 4rem;
            padding-bottom: 4rem;
        }

        .title{
            margin: 0 auto;
            text-align: center;
            letter-spacing: var(--letter-spacing-main);
        }

        text-align: center;

        form{
            margin-top: 5rem;

            input, textarea{
                margin: 0 auto;
                margin-top: 2rem;
                display: block;
            }

            input{
                min-height: 3rem;
                min-width: 60%;
            }

            textarea{
                min-width: 60%;
                margin-bottom: 5rem;
            }
        }
    }

    .copy{
        width: 10%;
        margin: 0 auto;
        margin-top: 5rem;
        text-align: center;

        .logo{
            margin: 0 auto;
            width: 50%;
        }
    }
`

const Contact = () => {
    const data = useStaticQuery(graphql`
    query {
      tiger: file(relativePath: { eq: "white-tiger.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {

  }, [])

    return (
        <Container>
            <div className="header" id="contact">
                <Navigation position="left"/>
                <h5 className="rivalSchool">RIVAL SCHOOL</h5>
            </div>

            <div className="form-container">
                <div className="contact-header">
                    <div className="title-container">
                        <h3 className="title">CONTACT</h3>
                        <Colors />
                    </div>
                </div>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                        <input type="text" name="name" id="name" placeholder="name"/>
                        <input type="email" name="email" id="email" placeholder="email"/>
                        <input type="tel" name="phone" id="phone" placeholder="phone"/>
                        <textarea name="message" id="message" placeholder="message us" rows="3" />
                        <ContactButton type="submit" color="black" title="SUBMIT" />
                </form>
            </div>
            <div className="copy">
                {/* <Img className='logo' fluid={data.tiger.childImageSharp.fluid}/> */}
                <p onClick={() => netlifyIdentity.open()}>RIVAL SCHOOL, LLC</p>
            </div>
        </Container>
    )
}

export default Contact