import React from 'react'
import styled from 'styled-components'

const Nav = styled.nav`
    max-width: 50%;
    text-align: center;

    ul{
        list-style-type: none;
        display: inline-flex;
    }

    li{
        margin-left: 2rem;
        margin-right: 2rem;
        text-decoration: underline;
        letter-spacing: var(--letter-spacing-text);

        &:hover{
            cursor: pointer;
        }
    }
`

const Navigation = ({ position }) => {
    return(
        <Nav style={{float: position}}>
            <ul>
                <a href="#about"><li>ABOUT</li></a>
                <a href="#contact"><li>CONTACT</li></a>
                <a href="#services"><li>SERVICES</li></a>
            </ul>
        </Nav>
    )
}

export default Navigation