import React from 'react'
import styled from 'styled-components';
import Navigation from './navigation/Navigation';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
    width: 100%;
    height: auto;
    padding-bottom: 10rem;

    .paragraph{
      letter-spacing: var(--letter-spacing-button);
      font-family: var(--main-font-light);
      font-size: 2rem;
      margin-bottom: 6rem;
    }

    .top-container{
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .title{
            max-width: 40%;
            margin-left: 2rem;

            h1{
                letter-spacing: var(--letter-spacing-main);
            }
        }

        .logo-container{
            width: 80%;
            margin: 0 auto;
        }
    }

    .center-container{
        width: 60%;
        margin: 0 auto;
        margin-top: 10vh;

        text-align: center;

        h3{
            letter-spacing: var(--letter-spacing-main);
        }

        p{
            width: 80%;
            margin: 0 auto;
            font-size: 2rem;
            letter-spacing: var(--letter-spacing-text);
        }

        }

        .img-container{
            width: 10%;
            margin: 0 auto;
        }
    }
`

const About = () => {
    const data = useStaticQuery(graphql`
    query {
      flag: file(relativePath: { eq: "flag.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      palm: file(relativePath: { eq: "palm.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ufo: file(relativePath: { eq: "ufo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Container>
            <Navigation position="right"/>
            <div className='top-container' id="about">
                <div className="title"><h1>ABOUT RIVAL SCHOOL</h1></div>
                <div className="logo-container">
                    {/* <Img className='logo' fluid={data.flag.childImageSharp.fluid} /> */}
                </div>
            </div>
            <div className='center-container'>
                <div className='center'>
                    <div className="img-container">
                        <Img className='tree' fluid={data.palm.childImageSharp.fluid} />
                    </div>
                    <h3>THE ROOTS</h3>
                    <p className="paragraph">
                    What started as a simple platform to building custom websites has evolved into a full range of business solutions including software and app development, branding, team training, project management and everything in between. Rival School started in Albuquerque, New Mexico by two software engineers who have vast experience in working in corporate America and building large scale applications. Rival School became a breeding ground for new partnerships with entrepreneurs and businesses who have vision and ideas, but need that expertise and the “special sauce” to bring vision to reality. One thing that sets us apart, is the ability to challenge an idea and reaffirm what the true goal is by establishing deeper “whys" to help achieve a realistic and successful solution. At Rival School, we believe that every project we touch should provide an experience that can be inspiring and life impacting.
                    </p>
                </div>
            </div>
            <div className='center-container'>
                <div className="center">
                    <div className="img-container">
                        <Img className='tree' fluid={data.ufo.childImageSharp.fluid} />
                    </div>
                    <h3>THE JOURNEY</h3>
                        <p className="paragraph">
                        It starts with what we call "brain matter”; throwing out all ideas, edge cases, pitfalls and possibilities. Sometimes the pressure challenges the integrity of the final product. We never stop focusing on the story and never stop reaffirming “why” to make certain we are building a product that can’t break, because we’ve already tried to break it ourselves. We use an agile approach to get a good product shipped fast and then adjust as needed. We keep the journey moving by providing ongoing resources to stay ahead of the competition and continue educating ourselves to be empowered to continue growing. Our team is diverse in experience and aspirations, while still having a common goal in mind to—make amazing products. While some individuals on the team are dreamers, others are challengers or helpers, Rival School is built this way intentionally to encourage balance, endless possibilities and to turn around a product we are proud of, quickly.
                        </p>
                </div>
                <div className='right'>
                </div>
            </div>
        </Container>)
}


export default About;