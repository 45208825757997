import React from "react"
import ContactButton from "./buttons/Contact"
import { motion } from 'framer-motion'
import Colors from './buttons/Colors'
import './header.css'

const Header = () => {
  return(
      <div className="container">
        <motion.h1 className="rival" style={{fontSize: '20rem' }}
          initial={{
            y: '-200px',
            opacity: 0.5
          }}
          animate={{
            y: '0',
            opacity: 1
          }}
          transition={{
            type: 'tween',
            duration: 1
          }}
        >RIVAL</motion.h1>
        <Colors/>
        <h1 className="school" style={{fontSize: '20rem' }}>SCHOOL</h1>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{duration: 2}}
          className='tag'
          >APP DEVELOPMENT, DESIGN, BRANDING</motion.h2>
        <a href="#contact">
          <ContactButton title="contact" color="black"/>
        </a>
      </div>
  )
}

export default Header
