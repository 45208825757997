import React from "react"
import styled from "styled-components"

const Container = styled.div`
    text-align: center;
    margin-top: 5rem;

    h3{
        letter-spacing: var(--letter-spacing-button);
    }

    p{
        width: 50%;
        margin: 0 auto;
        letter-spacing: var(--letter-spacing-text);
        font-family: var(--main-font-light);
        font-size: 2rem;
        margin-bottom: 6rem;
    }
`

const Service = ({ title, details}) => {
    return(
        <Container>
            <h3>{title}</h3>
            <p>{details}</p>
        </Container>
    )
}

export default Service;