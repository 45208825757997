import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from '../components/header'
import About from '../components/about'
import Contact from '../components/contact'
import Services from '../components/services'

const IndexPage = () => {

  return(
    <Layout>
      <SEO title="Home" />
      <Header />
      <About />
      <Services />
      <Contact />
    </Layout>
  )
}

export default IndexPage
